import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axiosInstance from "../../../Services/Interceptor";

const DeleteAcc = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  let authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : null;

  let notification_id = Cookies.get("notification_id")
    ? Cookies.get("notification_id")
    : null;

  useEffect(() => {
    // authTokens && authTokens ? "hello" :  ;
    if (authTokens) {
    } else {
      window.location.replace("/");
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    // toast.success("Account Deleted Successfully", {
    //   position: "top-right",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    // reset();

    axiosInstance
      .post(baseURL + "users/account-delete/")
      .then(function (response) {
        // console.log(response, " api response");
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .then((response) => {
        axiosInstance
          .delete(
            baseURL +
              `notification/gcm/device/delete/${parseInt(notification_id)}/`
          )
          .then((res) => {
            // return res;
            // console.log(res);
          });
      })
      .catch((error) => {
        // console.log(error, "  error");

        toast.error(error.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    Cookies.remove("access_token_user");
    Cookies.remove("refresh_token_user");
    Cookies.remove("notification_id");
    // Cookies.remove("notification_token");
    setTimeout(() => {
      return window.location.replace("/");
      // return window.history.pushState({}, undefined, "/");
    }, 1200);
  };
  return (
    <div className="relative">
      <div className="relative z-10 h-screen w-full flex justify-center items-center bg-primary/60 text-white">
        <div className="h-max w-full hidden sm:block relative -top-20 right-32">
          <img
            className="h-16 lg:h-32 w-auto mx-auto object-contain "
            src="/images/logoBangla.png"
            alt="logo"
          />
        </div>
        <div className="h-full w-full bg-white/50">
          <div className="h-full w-full flex justify-center items-center space-y-6 p-6 sm:p-0">
            <div className="max-w-xl w-full bg-red-200 p-8 sm:p-16 rounded-xl">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-4 text-gray-600"
              >
                <div>
                  <h2 className="capitalize font-bold  text-left text-3xl">
                    Delete Account
                  </h2>
                  <p className="text-left font-medium text-sm ">
                    Please insert your credientials
                  </p>
                </div>
                {/* input fileds */}
                <div className="space-y-4">
                  {/* Phone */}
                  <div className="">
                    <input
                      type="text"
                      className="bg-transparent border-b border-gray-600 px-2 py-1 w-full placeholder-gray-600 outline-none"
                      placeholder="Phone Number"
                      ng-pattern="^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$"
                      {...register("phone", { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors.phone && (
                      <span className="text-xs text-red-500">
                        This field is required*
                      </span>
                    )}
                  </div>
                </div>
                {/* buttons */}
                <div className="space-y-3 w-full">
                  <input
                    className="w-full bg-red-400 hover:bg-red-500 py-1 text-white font-bold rounded-full"
                    value="Delete Account"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute inset-0 z-0 h-screen w-screen object-cover "
        src="/images/wallpaper/5.jpg"
        alt="logo"
      />
    </div>
  );
};

export default DeleteAcc;
