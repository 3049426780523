import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { FaBlenderPhone, FaAngleRight } from "react-icons/fa";
import { LuShieldAlert } from "react-icons/lu";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import axiosInstance from "./Services/Interceptor";
import ReactAudioPlayer from "react-audio-player";
import audio from "./alerts/alarm-no3-14864.mp3";
import { useDispatch, useSelector } from "react-redux";
import { IoNotificationsSharp } from "react-icons/io5";
import { AiFillBell, AiOutlineCloseCircle } from "react-icons/ai";
import { CgExtension } from "react-icons/cg";
import DeleteAcc from "./Frontend/Components/DeleteAcc/DeleteAcc";
import { MdOutlineContactEmergency } from "react-icons/md";
import EmergencyContact from "./Frontend/Components/EmergencyContact/EmergencyContact";
import { CiBellOn, CiSettings } from "react-icons/ci";
import { RiLogoutBoxRLine, RiUserSettingsLine } from "react-icons/ri";
import PrivacyPolicy from "./Frontend/Components/PrivacyPolicy/PrivacyPolicy";
import AccountDelete from "./Frontend/Components/AccountDelete/AccountDelete";
import { getAuthToken, getRefreshToken } from "./Redux/Reducers/LoginReducer";
import { TbBrandGoogleHome, TbEmergencyBed } from "react-icons/tb";
import { SiLinkerd } from "react-icons/si";
import { GiCctvCamera } from "react-icons/gi";
import { getUserData } from "./Redux/Reducers/UserProfileReducer";
import { IoIosArrowForward, IoMdLogOut } from "react-icons/io";
import {
  getnotificationsID,
  getnotificationsToken,
} from "./Redux/Reducers/NotificationReducer";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import Notification from "./Frontend/Components/WebMessage/Notification";

//  ==========component calling using lazy loading============
const Home = React.lazy(() => import("./Frontend/Components/Home/Home"));
const Mqtt = React.lazy(() => import("./Frontend/Components/Mqtt/Mqtt"));
const Auth = React.lazy(() => import("./Frontend/Components/Auth/Auth"));
const SingleDevice = React.lazy(() =>
  import("./Frontend/Components/Device/SingleDevice")
);
const UserProfile = React.lazy(() =>
  import("./Frontend/Components/UserProfile/UserProfile")
);
const Error = React.lazy(() => import("./Frontend/Shared/Error/Error"));

const ActivityLog = React.lazy(() =>
  import("./Frontend/Components/ActivityLog/ActivityLog")
);
const Test = React.lazy(() => import("./Frontend/Helpers/Test"));
const RoomList = React.lazy(() =>
  import("./Frontend/Components/Room/RoomList")
);
const Groups = React.lazy(() =>
  import("./Frontend/Components/Group/GroupsList")
);
const Users = React.lazy(() => import("./Frontend/Components/Users/Users"));
const SingleGroup = React.lazy(() =>
  import("./Frontend/Components/Group/SingleGroup")
);
const GroupUserList = React.lazy(() =>
  import("./Frontend/Components/Group/GroupUserList")
);
const CameraGalleryList = React.lazy(() =>
  import("./Frontend/Components/CameraGallery/CameraGalleryList")
);
const SingleCamera = React.lazy(() =>
  import("./Frontend/Components/CameraGallery/SingleCamera")
);
const SingleRoom = React.lazy(() =>
  import("./Frontend/Components/Room/SingleRoom")
);
const HubRequtestAll = React.lazy(() =>
  import("./Frontend/Components/Permission/HubRequtestAll")
);
const HubList = React.lazy(() => import("./Frontend/Components/Hub/HubList"));
const AddDevice = React.lazy(() =>
  import("./Frontend/Components/Device/AddDevice")
);
const DeviceList = React.lazy(() =>
  import("./Frontend/Components/Device/DeviceList")
);
const TermsAndConditions = React.lazy(() =>
  import("./Frontend/Components/TermsAndConditions/TermsAndConditions")
);

const NotificationPage = React.lazy(() =>
  import("./Frontend/Components/NotificationPage/NotificationPage")
);

export const AllRoutes = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const { authToken, refreshToken } = useSelector((state) => state.loginInfo);
  const { navLocations } = useSelector((state) => state.navigation);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { notification_id } = useSelector((state) => state.notification);
  let notificationId = Cookies.get("notification_id")
    ? Cookies.get("notification_id")
    : notification_id;
  let authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : authToken;
  let refreshTokens = Cookies.get("refresh_token_user")
    ? Cookies.get("refresh_token_user")
    : refreshToken;

  useEffect(() => {
    dispatch(getAuthToken(authTokens));
    dispatch(getRefreshToken(refreshTokens));
  }, []);

  const [dynamicWidth, setDynamicWidth] = useState(false);

  const { notifications } = useSelector((state) => state.notification);
  const [AlarmType, setAlarmType] = useState(false);

  useEffect(() => {
    const myObject = Cookies.get("alarm_dict")
      ? JSON.parse(Cookies.get("alarm_dict"))
      : {};

    const isObjectEmpty = Object.keys(myObject).length === 0;
    if (isObjectEmpty) {
      setAlarmType(false);
    } else {
      setAlarmType(true);
    }
  }, [Cookies.get("alarm_dict")]);

  // Get profile and notification data
  useEffect(() => {
    if (authTokens) {
      axiosInstance
        .get(baseURL + "profile/users/general/")
        .then((response) => {
          // console.log(response.data.data);
          dispatch(getUserData(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [authToken]);

  const navigation = [
    {
      name: "Home",
      href: "/",
      icon: <TbBrandGoogleHome className="h-5 w-5" />,
      current: navLocations.pathname === "/" ? true : false,
    },
    {
      name: "Hub List",
      href: "/hubs",
      icon: <SiLinkerd className="h-5 w-5" />,
      current: navLocations.pathname === "/hubs" ? true : false,
    },
    {
      name: "Camera",
      href: "/cameras",
      icon: <GiCctvCamera className="h-5 w-5" />,
      current: navLocations.pathname === "/cameras" ? true : false,
    },
  ];
  const userNavigation = [
    {
      name: "User Profile",
      href: "/user-profile",
      icon: <RiUserSettingsLine className="h-5 w-5" />,
      current: navLocations.pathname === "/user-profile" ? true : false,
    },
    {
      name: "Hub request",
      href: "/hub-request",
      icon: <VscGitPullRequestCreate className="h-5 w-5" />,
      current: navLocations.pathname === "/hub-request" ? true : false,
    },
    {
      name: "Emergency Contact",
      href: "/emergency-contact",
      icon: <TbEmergencyBed className="h-5 w-5" />,
      current: navLocations.pathname === "/emergency-contact" ? true : false,
    },
  ];

  const [logoutModal, setLogoutModal] = useState(false);
  // const navigate = useNavigate();
  const handleLogOut = () => {
    // console.log("first")
    axiosInstance
      .delete(
        baseURL + `notification/gcm/device/delete/${parseInt(notificationId)}/`
      )
      .then((res) => {
        // return res;
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });

    axios
      .post(
        baseURL + "users/logout/",
        {
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        Cookies.remove("access_token_user");
        Cookies.remove("refresh_token_user");
        Cookies.remove("notification_id");
        Cookies.remove("notification_token");
        Cookies.remove("alarm_dict");
        dispatch(getAuthToken(null));
        dispatch(getRefreshToken(null));
        dispatch(getnotificationsID(null));
        dispatch(getnotificationsToken(null));
        setLogoutModal(false);
        window.location.replace("/");
      })
      .catch((err) => {
        // return err;
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [profileView, setProfileView] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [notificanView, setNotificationView] = useState(false);

  return (
    <BrowserRouter>
      <section className="relative z-10 h-screen lg:h-screen w-full  p-1  4xl:p-16 bg-GShade1/50 ">
        {authTokens ? (
          <div className="h-full rounded-3xl  flex shadow-sm shadow-GShade4">
            <Disclosure
              as="nav"
              className="border-slate-300 rounded-l-3xl bg-GShade2 "
            >
              <div
                className={` ${
                  dynamicWidth ? "w-24" : "w-64"
                } transition-all duration-300 h-full  relative  hidden lg:block`}
              >
                <div className="flex flex-col w-full h-full items-start justify-between">
                  <div className="w-full h-full flex flex-col items-start">
                    <div
                      className={`flex-shrink-0 flex justify-between items-center w-full py-4 transition-all ease-in  ${
                        dynamicWidth ? " pl-4 " : " px-8"
                      }`}
                    >
                      {dynamicWidth ? (
                        <Link to="/" className="pl-5">
                          <img
                            alt="Satarko Logo"
                            src="/images/sotorko only logo 1.svg"
                            className="h-12 w-auto object-contain mx-auto"
                          />
                        </Link>
                      ) : (
                        <Link to="/">
                          <img
                            alt="Satarko Logo"
                            src="/images/logo1.png"
                            className="h-10 w-auto object-contain"
                          />
                        </Link>
                      )}
                    </div>
                    <div className="hidden lg:flex flex-col justify-between h-full w-full border-t0 border-slate-300 py-4 pl-8">
                      <div className="w-full flex flex-col justify-start items-baseline space-y-4">
                        {/* alarm */}
                        {AlarmType && (
                          <Link
                            to="/hubs"
                            className="flex justify-center items-center w-[80%] border border-GShade4  bg-black rounded-full"
                          >
                            <ReactAudioPlayer
                              className="hidden "
                              src={audio}
                              listenInterval={1000}
                              autoPlay
                              loop
                              volume={audio || 0.7}
                            />

                            <div className="flex justify-center items-center gap-2 px-2 w-max bg-GShade40 rounded-full">
                              <img
                                className="h-8 w-max object-contain rounded-full0"
                                src="/images/error/warning.gif"
                                alt="alarm"
                              />
                              {/* <img
                                className="h-8 w-max object-contain"
                                src="/images/error/warning1.gif"
                                alt="alarm"
                              /> */}
                            </div>
                          </Link>
                        )}
                        <Link
                          to="/notification"
                          className={classNames(
                            navLocations.pathname === "/notification"
                              ? "text-white bg-GShade3"
                              : "text-slate-300 hover:text-white",
                            " pl-4 py-2 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-sm"
                          )}
                        >
                          <AiFillBell className=" h-5 w-5 " />

                          <p
                            className={`text-sm font-medium text-white text-left ${
                              dynamicWidth ? "hidden" : "block"
                            }`}
                          >
                            Events
                          </p>
                        </Link>
                        <div className="space-y-2 w-full">
                          <p
                            className={`text-xs font-medium text-white text-left ${
                              dynamicWidth ? "hidden" : "block"
                            }`}
                          >
                            Dashboard
                          </p>
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              aria-current={item.current ? "page" : undefined}
                              className={classNames(
                                item.current
                                  ? "text-white bg-GShade3"
                                  : "text-slate-300 hover:text-white",
                                " pl-4 py-2 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-sm"
                              )}
                            >
                              {dynamicWidth ? (
                                <span title={item.name}> {item.icon}</span>
                              ) : (
                                <span
                                  className="flex justify-start items-center gap-2 transition ease-in-out"
                                  title={item.name}
                                >
                                  {item.icon}
                                  {item.name}
                                </span>
                              )}
                            </Link>
                          ))}
                        </div>
                        <div className="space-y-2 w-full">
                          {dynamicWidth ? (
                            ""
                          ) : (
                            <p className="text-xs font-medium text-white text-left">
                              Settings
                            </p>
                          )}

                          {userNavigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              aria-current={item.current ? "page" : undefined}
                              className={classNames(
                                item.current
                                  ? "text-white bg-GShade3"
                                  : "text-slate-300 hover:text-white",
                                " pl-4 py-2 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-sm transition-all eas"
                              )}
                            >
                              {dynamicWidth ? (
                                <span title={item.name}> {item.icon}</span>
                              ) : (
                                <p className="flex justify-start items-center gap-2  ">
                                  {item.icon}
                                  {item.name}
                                </p>
                              )}
                            </Link>
                          ))}
                        </div>
                      </div>
                      <div className="w-full pr-8">
                        <div
                          onClick={() => setLogoutModal(true)}
                          className={`w-full border border-GShade1  py-1.5 px-4 text-sm font-medium text-slate-300 uppercase hover:text-white rounded-full cursor-pointer flex ${
                            dynamicWidth ? "flex-col" : "flex-row"
                          } items-center justify-center gap-2 transition-all duration-100 delay-100`}
                        >
                          <IoMdLogOut className="h-4 w-4 " />
                          {dynamicWidth ? (
                            ""
                          ) : (
                            <span className="transition-all duration-300 delay-500 ">
                              Logout
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden ">
                    <div className="ml-4 flex gap-8 items-center md:ml-6 rounded-full p-0.5 border border-slate-400">
                      <button
                        type="button"
                        className="relative rounded-full border border-slate-800 hover:border-slate-400 bg-slate-800 p-1 text-slate-400 focus:outline-none"
                      >
                        <LuShieldAlert aria-hidden="true" className="h-5 w-5" />
                      </button>

                      {/* Profile dropdown */}
                      <div className="relative rounded-full text-slate-400 focus:outline-none flex justify-end items-center gap-2">
                        <div className="text-end text-sm">
                          <p className="uppercase font-semibold">
                            {userProfile?.full_name}
                          </p>
                        </div>

                        <img
                          alt=""
                          src={
                            userProfile?.image
                              ? userProfile?.image
                              : "/images/userIcon.png"
                          }
                          className="h-8 w-8 rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2 flex absolute top-0 left-0  md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md bg-red-500 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 ">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon
                        aria-hidden="true"
                        className="block h-6 w-6 group-data-[open]:hidden"
                      />
                      <XIcon
                        aria-hidden="true"
                        className="hidden h-6 w-6 group-data-[open]:block"
                      />
                    </Disclosure.Button>
                  </div>
                </div>
                <IoIosArrowForward
                  className={`w-6 h-6 bg-GShade4 text-GShade2 p-1  rounded-full absolute top-5 -right-3  z-50 cursor-pointer hidden md:block ${
                    dynamicWidth ? "" : "rotate-180"
                  } `}
                  onClick={() => setDynamicWidth(!dynamicWidth)}
                />
              </div>
            </Disclosure>
            {/* mobile menu icon */}
            <MenuIcon
              onClick={() => setIsMobileMenuOpen(true)}
              aria-hidden="true"
              className="block h-8 w-8 lg:hidden absolute top-2 left-2 z-[50] bg-GShade2 text-GShade4 rounded-sm p-1"
            />

            {/* mobile menu  */}
            <Transition appear show={isMobileMenuOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setIsMobileMenuOpen(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-60" />
                </Transition.Child>

                <div className="w-[70vw] xs:w-[60vw] sm:w-[50vw] fixed inset-0 overflow-y-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full h-full max-w-lg transform overflow-hidden rounded-lg bg-transparent  align-middled transition-all">
                      <Dialog.Title className="h-full w-full bg-GShade2 text-GShade4  flex flex-col justify-start items-start space-y-4 pl-4 py-2">
                        <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>
                          <img
                            alt="Satarko Logo"
                            src="/images/logo1.png"
                            className="h-10 w-20 object-contain mx-auto"
                          />
                        </Link>
                        <div className="grow h-full w-full space-y-2">
                          <Link
                            onClick={() => setIsMobileMenuOpen(false)}
                            to="/notification"
                            className={classNames(
                              navLocations.pathname === "/notification"
                                ? "text-white bg-GShade3"
                                : "text-slate-300 hover:text-white",
                              " pl-4 py-1 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-xs"
                            )}
                          >
                            <AiFillBell className=" h-5 w-5 " />

                            <p
                              className={`text-xs font-medium text-white text-left `}
                            >
                              Events
                            </p>
                          </Link>
                          <div className="space-y-1 w-full">
                            <p
                              className={`text-xs font-medium text-white text-left`}
                            >
                              Dashboard
                            </p>
                            {navigation.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                aria-current={item.current ? "page" : undefined}
                                className={classNames(
                                  item.current
                                    ? "text-white bg-GShade3"
                                    : "text-slate-300 hover:text-white",
                                  " pl-4 py-1 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-xs"
                                )}
                              >
                                <span
                                  className="flex justify-start items-center gap-2 transition ease-in-out"
                                  onClick={() => setIsMobileMenuOpen(false)}
                                  title={item.name}
                                >
                                  {item.icon}
                                  {item.name}
                                </span>
                              </Link>
                            ))}
                          </div>
                          <div className="space-y-1 w-full">
                            <p className="text-xs font-medium text-white text-left">
                              Settings
                            </p>

                            {userNavigation.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                aria-current={item.current ? "page" : undefined}
                                className={classNames(
                                  item.current
                                    ? "text-white bg-GShade3"
                                    : "text-slate-300 hover:text-white",
                                  " pl-4 py-1 rounded-l-full font-medium flex justify-start items-center gap-2 w-full uppercase text-xs transition-all eas"
                                )}
                              >
                                <p
                                  className="flex justify-start items-center gap-2"
                                  onClick={() => setIsMobileMenuOpen(false)}
                                >
                                  {item.icon}
                                  {item.name}
                                </p>
                              </Link>
                            ))}
                          </div>
                        </div>

                        <div
                          onClick={() => setLogoutModal(true)}
                          className={`w-3/4 mx-auto border border-GShade1  py-1.5 px-4 text-xs font-medium text-slate-300 uppercase hover:text-white rounded-full cursor-pointer mr-4 flex ${
                            dynamicWidth ? "flex-col" : "flex-row"
                          } items-center justify-center gap-2 transition-all duration-100 delay-100`}
                        >
                          <IoMdLogOut className="h-4 w-4 " />
                          {dynamicWidth ? (
                            ""
                          ) : (
                            <span className="transition-all duration-300 delay-500 ">
                              Logout
                            </span>
                          )}
                        </div>
                        <XIcon
                          onClick={() => setIsMobileMenuOpen(false)}
                          aria-hidden="true"
                          className="h-6 w-6 absolute top-0 right-1 border border-GShade4 rounded-full"
                        />
                      </Dialog.Title>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>

            <main className="relative h-auto  lg:h-full w-full bg-GShade3 rounded-r-3xl">
              <div className="relative h-full w-full">
                {/* routes */}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <Home />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/hubs"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <HubList />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/cameras"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <CameraGalleryList />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/camera/:id"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <SingleCamera />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/user-profile"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <UserProfile />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/devices/:id"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <DeviceList />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/device/:id"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <SingleDevice />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/hub-request"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <HubRequtestAll />
                      </React.Suspense>
                    }
                  />

                  <Route
                    exact
                    path="/emergency-contact"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <EmergencyContact />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/test"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <Test />
                      </React.Suspense>
                    }
                  />

                  <Route
                    exact
                    path="/*"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <Error />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/account-deletion"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <AccountDelete />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/notification"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="flex justify-center items-center h-full w-full">
                            <img
                              className="h-72 w-auto object-contain "
                              src="/images/loading.gif"
                              alt="loader"
                            />
                          </div>
                        }
                      >
                        <NotificationPage />
                      </React.Suspense>
                    }
                  />
                </Routes>
              </div>

              {/* profile side bar view */}
              <div
                className={
                  (profileView ? "block opacity-100" : "hidden opacity-0") +
                  " absolute inset-0 z-40 flex transition-opacity duration-200   "
                }
              >
                <div
                  onClick={() => {
                    setProfileView(false);
                  }}
                  className="flex-1 bg-primary opacity-70"
                ></div>
                <div className="max-w-[30%] 2xl:max-w-[20%] w-full bg-lightPrimary dark:bg-black h-full text-white p-8 space-y-2 divide-y divide-primary flex flex-col justify-between">
                  <div className="h-full w-full divide-y divide-primary space-y-2">
                    {false ? (
                      <img
                        className="inline-block h-24 w-24 rounded-full object-contain border-[2px] border-lightAccent dark:border-gray-600 p-0.5"
                        // src={user.data.image}
                        alt="profile"
                      />
                    ) : (
                      <img
                        className="inline-block h-24 w-24 rounded-full object-contain border-[2px] border-gray-600 p-0.5"
                        src="/images/userIcon.png"
                        alt=""
                      />
                    )}

                    {false ? (
                      <p className="text-sm text-black dark:text-gray-400  font-semibold py-2 uppercase pt-2 ">
                        {/* {user.data.full_name} */}dfg df g
                      </p>
                    ) : (
                      <p className="text-sm text-lightSecondary dark:text-gray-400 font-semibold py-2 uppercase pt-2 ">
                        Profile
                      </p>
                    )}

                    <div className="py-2 space-y-2 my-2 ">
                      <div className="flex justify-start items-center gap-x-1">
                        <div className="flex justify-start items-center gap-x-1">
                          <FaBlenderPhone className="h-4 w-4 text-lightSecondary dark:text-white" />
                          <p className="uppercase text-sm font-semibold text-lightSecondary dark:text-gray-500">
                            Contact :
                          </p>
                        </div>
                        <p className="font-bold text-lightSecondary dark:text-white ">
                          {/* {user && user.data.user_id?.phone} */}dfg dg dsg
                        </p>
                      </div>
                    </div>
                    <div className="py-2 space-y-2">
                      <div className="flex justify-between items-center bg-lightSecondary dark:bg-primary p-4 rounded-md cursor-pointer">
                        <Link
                          to="/userProfile"
                          className="flex justify-start items-center gap-x-2"
                          onClick={() => {
                            setProfileView(false);
                          }}
                        >
                          <CiSettings className="w-5 h-5 text-lightPrimary dark:text-white" />

                          <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                            Profile Setting
                          </p>
                        </Link>

                        <FaAngleRight className="w-5 h-5 text-lightPrimary dark:text-white" />
                      </div>
                      <Link
                        to="/permission"
                        onClick={() => setProfileView(false)}
                        className="block bg-lightSecondary dark:bg-primary p-4 rounded-md cursor-pointer"
                      >
                        <div className="flex justify-between items-center bg-opacity-30">
                          <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                            Hub Request
                          </p>
                          <CgExtension className="h-6 w-6 text-lightPrimary dark:text-white" />
                        </div>
                      </Link>
                      <Link
                        to="/emergency-contact"
                        onClick={() => setProfileView(false)}
                        className="flex justify-between items-center bg-lightSecondary dark:bg-primary  p-4 rounded-md cursor-pointer"
                      >
                        <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                          Emergency Contact
                        </p>

                        <MdOutlineContactEmergency className="h-5 w-5 text-lightPrimary dark:text-white" />
                      </Link>
                    </div>
                  </div>
                  <div className="py-4">
                    <img
                      className=" h-44 w-full object-contain"
                      src="/images/playStore.png"
                      alt="playstore"
                    />
                  </div>

                  <div className="py-2">
                    <div className="flex justify-start items-center gap-x-2 bg-lightSecondary dark:bg-primary dark:bg-opacity-30 p-4 rounded-md cursor-pointer">
                      <RiLogoutBoxRLine className="w-6 h-6 text-lightPrimary dark:text-white" />

                      <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                        Log out
                      </p>
                    </div>
                    <p className="text-xs py-2 text-center capitalize text-lightSecondary dark:text-white">
                      &copy; {new Date().getFullYear()} all rights reserved by{" "}
                      <Link
                        to="https://satarko.com/"
                        className="text-lightAccent dark:text-yellow-700 uppercase font-bold"
                      >
                        Shatarko
                      </Link>
                    </p>
                    <div className="flex justify-center items-center gap-8">
                      <Link
                        to="/terms-and-conditions"
                        className="text-[10px] dark:text-[9px] text-lightAccent dark:text-yellow-500 text-end"
                      >
                        Terms &amp; Conditions
                      </Link>
                      <Link
                        to="/privacy-policy"
                        className="text-[10px] dark:text-[9px] text-lightAccent dark:text-yellow-500 text-end"
                      >
                        Privacy &amp; Policy
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Notification />
          </div>
        ) : (
          <Routes>
            <Route
              exact
              path="/terms-and-conditions"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <TermsAndConditions />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/account-deletion"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <AccountDelete />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/privacy-policy"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <PrivacyPolicy />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <Auth />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/delete-account"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <DeleteAcc />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/*"
              element={
                <React.Suspense
                  fallback={
                    <div className="flex justify-center items-center h-full w-full">
                      <img
                        className="h-72 w-auto object-contain "
                        src="/images/loading.gif"
                        alt="loader"
                      />
                    </div>
                  }
                >
                  <Error />
                </React.Suspense>
              }
            />
          </Routes>
        )}
      </section>
      {/* modals */}
      <>
        {/* logout */}
        <Transition appear show={logoutModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setLogoutModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-transparent  align-middle transition-all">
                    <div
                      className={
                        "space-y-4 p-8 rounded-lg bg-GShade3  h-auto max-w-3xl m-auto text-gray-50"
                      }
                    >
                      <div>
                        <h2 className="capitalize font-bold text-gray-50 text-left text-3xl flex justify-start items-center gap-x-2">
                          <IoMdLogOut />
                          <span className="uppercase">Logout</span>
                        </h2>
                        <p className="text-left font-medium text-sm">
                          Are you want to logout ?
                        </p>
                      </div>

                      {/* buttons */}
                      <div className="flex justify-between items-center gap-4">
                        <p
                          onClick={() => setLogoutModal(false)}
                          className="w-full  py-1 text-gray-50 hover:text-GShade2 border border-GShade4 font-bold rounded-full cursor-pointer"
                        >
                          Cancel
                        </p>
                        <p
                          onClick={() => handleLogOut()}
                          className="w-full border border-red-400 hover:border-red-700 py-1 text-red-400 hover:text-red-700 font-bold rounded-full cursor-pointer"
                        >
                          Logout
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>

      <ToastContainer />
    </BrowserRouter>
  );
};
