import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const { register, handleSubmit } = useForm();
  const handleContactData = (data) => {
    console.log(data);
  };
  return (
    <section className="p-8 h-full w-full flex flex-col gap-4 bg-GShade2 overflow-hidden text-GShade4 rounded-3xl">
      <Link to="/">
        <img
          className="h-10 w-auto object-contain mx-auto xl:mx-0"
          src="/images/logo1.png"
          alt="Workflow"
        />
      </Link>
      <p className="text-lg xl:text-3xl text-center font-bold uppercase">
        privacy policy
      </p>
      <div className="max-w-7xl mx-auto text-left space-y-4 h-full w-full overflow-auto pr-2">
        <div>
          <ul className="space-y-2 list-disc pl-6 ">
            <li>
              <a href="https://zicoretech.com/" className="text-yellow-400">
                Zicore Technologies
              </a>{" "}
              built the Satarko app as a commercial app. This service is
              provided by{" "}
              <a href="https://zicoretech.com/" className="text-yellow-400">
                Zicore Technologies
              </a>{" "}
              at no cost and is intended.
            </li>
            <li>
              This page is used to inform website visitors regarding our
              policies regarding the collection, use, and disclosure of Personal
              Information if anyone decides to use our Service.
            </li>
            <li>
              If you choose to use our service, then you agree to the collection
              and use of information about this policy. The personal data that
              we collect are used for providing and improving the service. We
              will not use or share your information with anyone except as
              described in this Privacy Policy.
            </li>
            <li>
              The terms used in this Privacy Policy have the same meaning as in
              our Terms & Conditions. Which is accessible at Satarko{" "}
              <a
                href="https://beta.satarko.com/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                className="text-yellow-400"
              >
                Terms & Conditions.
              </a>
            </li>
          </ul>
        </div>
        {/* information collection  */}
        <div className="space-y-2">
          <h1 className="text-base xl:text-xl font-bold uppercase">
            Information Collection and Use
          </h1>

          <article className="font-light text-sm ">
            For a better experience while using our Service, We may require you
            to provide us with certain personally identifiable information,
            including the user's name, address, location, and pictures. The
            information that we request will be retained by us and used as
            described in this privacy policy.{" "}
          </article>
        </div>
        {/* log data  */}
        <div className="space-y-2">
          <h1 className="text-base xl:text-xl font-bold uppercase">Log Data</h1>
          <article className="font-light text-sm ">
            We want to inform you that whenever you use our Service, in case of
            an error in the app we collect data and information through
            third-party products on your phone called Log Data. This Log Data
            may include information such as your device’s Internet Protocol
            (“IP”) address, device name, operating system version, configuration
            of the app when utilizing our Service, the time and date of your use
            of the Service, and other statistics.
          </article>
        </div>
        {/* cookies  */}
        <div className="space-y-2">
          <h1 className="text-base xl:text-xl font-bold uppercase">Cookies</h1>
          <article className="font-light text-sm ">
            Cookies are files with a small amount of data that is commonly used
            as an anonymous unique identifier. These are sent to your browser
            from the website that you visit and are stored on your device’s
            internal memory
          </article>
          <article className="font-light text-sm ">
            However, the app may use third-party code and libraries that use
            “cookies” to collect information and to improve their services. You
            have the option to either accept or refuse these cookies and know
            when a cookie is being sent to your device. If you choose to refuse
            our cookies, you may not be able to use some portions of this
            Service.
          </article>
        </div>
        {/* security  */}
        <div className="space-y-2">
          <h1 className="text-base xl:text-xl font-bold uppercase">Security</h1>
          <article className="font-light text-sm ">
            We value your trust in providing us with your Personal Information,
            thus we are striving to use commercially acceptable means of
            protecting it. To delete your account, log in to Satarko go to your
            profile section, and then delete your account. If you delete your
            account, within ninety(90) days all of your data will be permanently
            deleted.
          </article>
        </div>
        {/* changes to policy  */}
        <div className="space-y-2">
          <h1 className="text-base xl:text-xl font-bold uppercase">
            Changes to This Privacy Policy
          </h1>
          <article className="font-light text-sm ">
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page.
          </article>
        </div>
        {/* contact us  */}
        <div className="space-y-2">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center ">
              Contact Us
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-center  sm:text-xl">
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
            <form
              className="space-y-8"
              onSubmit={handleSubmit(handleContactData)}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium "
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow-sm bg-transparent border border-gray-300 text-gray-50 text-sm rounded-lg focus:outline-blue-400 focus:border-primary-500 block w-full p-2.5 "
                  placeholder="name@example.com"
                  {...register("email")}
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-50 dark:text-gray-400"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-50 bg-transparent rounded-lg shadow-sm border border-gray-300 focus:outline-blue-400 focus:border-primary-500"
                  placeholder="Leave a comment..."
                  {...register("contactMessage")}
                ></textarea>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="capitalize py-3 px-5 text-sm font-medium text-center text-GShade2 rounded-full bg-GShade4 sm:w-fit hover:bg-primary-800 focus:outline-none "
                >
                  contact us
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
