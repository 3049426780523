import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import store from "../Redux/Store"; // Make sure to import your Redux store

const baseURL = process.env.REACT_APP_BASE_URL;

let authTokens = Cookies.get("access_token_user")
  ? Cookies.get("access_token_user")
  : null;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${authTokens}`,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  // Retrieve token from cookies or Redux store
  authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : store.getState().loginInfo.authToken; // Access authToken from Redux store

  req.headers.Authorization = `Bearer ${authTokens}`;

  const user = jwt_decode(authTokens);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (!isExpired) return req;

  try {
    const response = await axios.post(`${baseURL}token/refresh/`, {
      refresh: Cookies.get("refresh_token_user")
        ? Cookies.get("refresh_token_user")
        : store.getState().loginInfo.refreshToken,
    });

    Cookies.set("refresh_token_user", response.data.refresh);
    Cookies.set("access_token_user", response.data.access);
    req.headers.Authorization = `Bearer ${response.data.access}`;
  } catch (error) {
    console.error("Error refreshing token", error);
  }

  return req;
});

export default axiosInstance;
