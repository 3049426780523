import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from "js-cookie";
import { setCookie } from "./Frontend/Helpers/Session";
import axiosInstance from "./Services/Interceptor";
import { useDispatch, useSelector } from "react-redux";
import {
  getnotificationsID,
  getnotificationsToken,
} from "./Redux/Reducers/NotificationReducer";
import { useEffect, useState } from "react";

const apiKey = process.env.REACT_APP_FIREBASE_APIKEY;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.REACT_APP_FIREBASE_APPID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENTID;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "django-push-demo.firebaseapp.com",
  projectId: "django-push-demo",
  storageBucket: "django-push-demo.appspot.com",
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

export const FireNotification = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  const { authToken } = useSelector((state) => state.loginInfo);
  let authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : authToken;
  const { notification_token } = useSelector((state) => state.notification);
  let token = Cookies.get("notification_token")
    ? Cookies.get("notification_token")
    : notification_token;

  const [currentTokenFirebase, setCurrentTokemFirebase] = useState(null);

  // Example function to retrieve cookie
  if (authTokens && !token) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey:
        "BOSPqZgDOnFVY_GxGFoMISN_IARqrgjpbCkRSF9H9rrY4AsbiL5B-kMVcKW74QgQ0b5hA_o-v_5jjwQvDbjlKJU",
    })
      .then((currentToken) => {
        setCookie("notification_token", currentToken);
        dispatch(getnotificationsToken(currentToken));
        setCurrentTokemFirebase(currentToken);
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token. ", err);
      });
  }
  useEffect(() => {
    if (currentTokenFirebase) {
      axiosInstance
        .post(baseURL + "notification/gcm/device/create/", {
          registration_id: currentTokenFirebase,
        })
        .then(function (response) {
          // return response
          setCookie("notification_id", response.data.data.id);
          // console.log(response);
          dispatch(getnotificationsID(response.data.data.id));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [currentTokenFirebase]);
  return <></>;
};
