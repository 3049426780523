import Cookies from "js-cookie";
import React from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { getnotifications } from "../../../Redux/Reducers/NotificationReducer";

export default function Notification() {
  const dispatch = useDispatch();
  const port = "prod.satarko.com";
  // console.log(port);
  const { authToken } = useSelector((state) => state.loginInfo);
  let authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : authToken;
  // console.log(authTokens)
  //Public API that will echo messages sent to it back to the client
  const socketUrl = `wss://${port}/ws/notifications/?token=${authTokens}`;

  const { readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      // console.log("Connected! Notification");
    },
    onClose: () => {
      // console.log("Disconnected! Notification");
    },
    // onMessage handler
    onMessage: (e) => {
      // console.log("data from ws", JSON.parse(e.data));
      if (JSON.parse(e.data).data) {
        dispatch(getnotifications(JSON.parse(e.data)));
        // if()
        // setCookie("alarm_dict", true);
      }
      // props.onDataChange(JSON.parse(e.data));
    },
  });
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  return <></>;
}
