import { createSlice } from "@reduxjs/toolkit";

export const UserProfileSlice = createSlice({
  name: "profile",
  initialState: {
    userProfile: [],
  },

  reducers: {
    getUserData: (state, action) => {
      state.userProfile = action.payload;
      // console.log(action.payload, "reducer");
    },
  },
});

export const { getUserData } = UserProfileSlice.actions;

export default UserProfileSlice.reducer;
