import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon, PhoneIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import { LuBellDot, LuMapPin } from "react-icons/lu";
import { GoAlert, GoClock } from "react-icons/go";
import {
  MdDeleteOutline,
  MdOutlineContactEmergency,
  MdOutlineEmergencyShare,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../Services/Interceptor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getNavLocation } from "../../../Redux/Reducers/NavigationReducer";
import { TbAlertOctagon, TbEmergencyBed } from "react-icons/tb";
import { VscGitPullRequestCreate } from "react-icons/vsc";

const EmergencyContact = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [count, setCount] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNavLocation(location));
  }, []);
  //delete account id
  const [deleteContactId, setDeleteContactId] = useState(0);

  const { notifications } = useSelector((state) => state.notification);
  useEffect(() => {
    setCount(count + 1);
  }, [notifications]);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [panicEvents, setPanicEvents] = useState([]);

  useEffect(() => {
    // getting emergency contact
    axiosInstance
      .get(baseURL + "emergency-contact/")
      .then((res) => {
        setContacts(res.data);
      })
      .catch((err) => {});

    axiosInstance
      .get(baseURL + "notification/?event_type=panic")
      .then((response) => {
        setPanicEvents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [count]);

  // delete emergency contact
  let [isOpenEmergencyContactDelete, setIsOpenEmergencyContactDelete] =
    useState(false);

  const handleDeleteEmergencyContact = () => {
    axiosInstance
      .delete(baseURL + `emergency-contact/${deleteContactId}/`)
      .then((response) => {
        setIsLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenEmergencyContactDelete(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenEmergencyContactDelete(false);
      });
  };

  //   add emergency contact
  let [isOpenAddEmergencyContact, setIsOpenAddEmergencyContact] =
    useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    axiosInstance
      .post("emergency-contact/request/", {
        phone: data.phone,
      })
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenAddEmergencyContact(false);
        reset();
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenAddEmergencyContact(false);
        reset();
      });
  };

  const [pagination, setPagination] = useState(null);
  useEffect(() => {
    if (pagination) {
      axiosInstance
        .get(pagination?.replace("http://", "https://"))
        .then(function (response) {
          setPanicEvents(response.data);
        })
        .catch((error) => {
          console.log(error, "  error");
        });
    }
  }, [pagination]);

  return (
    <section className="relative h-auto sm:h-full 3xl:h-full w-full p-4 3xl:p-8 flex flex-col gap-4 bg-GShade3 rounded-r-3xl">
      <div className="flex justify-start items-center gap-4 w-full py-2 sm:px-4 border-b border-GShade1 text-[8px] md:text-xs xl:text-lg">
        <div className="w-full py-1.5 px-4  font-medium uppercase text-gray-700 rounded-full flex justify-start items-center gap-2  ">
          <TbEmergencyBed className="h-4 3xl:h-6 w-4 3xl:w-6 " />
          Emergency Contact List
        </div>
        <div
          onClick={() => setIsOpenAddEmergencyContact(true)}
          className="whitespace-nowrap w-max border border-GShade1 bg-GShade4 py-1.5 px-4 font-medium text-GShade2 rounded-full flex justify-start items-center gap-2 cursor-pointer uppercase xl:text-xs"
        >
          <VscGitPullRequestCreate className="h-4 w-4 " />
          Create Contact
        </div>
      </div>

      <div className="flex-1 flex flex-col 2xl:flex-row justify-between itens-start gap-4 w-full h-full overflow-hidden">
        {/* emergency contacts */}
        <div className="w-full h-auto 2xl:h-full overflow-hidden  space-y-2">
          <div className="w-full h-full pr-2 overflow-auto divide-y divide-GShade1">
            {contacts.results?.data[0]?.emergency_contact.length > 0 ? (
              contacts.results?.data[0]?.emergency_contact.map(
                (item, index) => (
                  <div
                    key={index}
                    className=" w-full  flex  justify-between items-center py-2 divide-x divide-GShade1 "
                  >
                    <div className="flex justify-start  items-center gap-4 text-start ">
                      <p className="text-center text- sm:text-3xl font-bold p-2 h-10 sm:h-14 w-10 sm:w-14 text-GShade1 border-2 border-GShade1 rounded-full">
                        {item.name ? item.name.charAt(0) : "E"}
                      </p>
                      <div className="flex justify-start flex-col items-start text-gray-700 space-y-1">
                        <h2 className="font-medium text-xs sm:text-base">
                          {item.name}
                        </h2>
                        <p className="flex justify-start items-center gap-x-1 text-xs sm:text-sm">
                          <PhoneIcon className="h-4 w-4 " />
                          <span>{item.phone}</span>
                        </p>
                      </div>
                    </div>

                    <div className="w-max text-[8px] sm:text-xs space-y-2 text-left pl-2 sm:px-4">
                      <p className="uppercase text-GShade2">Emergency</p>
                      <p
                        className="text-red-700 cursor-pointer"
                        onClick={() => {
                          setDeleteContactId(item.id);
                          setIsOpenEmergencyContactDelete(true);
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="col-span-2 flex flex-col justify-center items-center gap-8 w-full h-full text-gray-700">
                <TbEmergencyBed className="h-8 sm:h-20 2xl:h-40 w-8 sm:w-20 2xl:w-40 " />
                <p className="text-xs sm:text-sm">No contact Available</p>
              </div>
            )}
          </div>
        </div>
        {/* panic alert list */}
        <div className="w-full h-[50vh] 2xl:h-full flex flex-col  gap-4 bg-GShade2 rounded-t-lg">
          <div className="flex justify-start 3xl:justify-end items-center gap-4 w-full py-2 px-4 bg-GShade20 border-b border-GShade1">
            <div className="w-max py-1.5 px-4 text-xs sm:text-lg font-medium uppercase text-GShade4 rounded-full flex justify-start 3xl:items-center gap-2 ">
              <MdOutlineEmergencyShare className="h-4 3xl:h-6 w-4 3xl:w-6 " />
              Panic event list
            </div>
          </div>

          <div className="flex-1 h-full w-full px-1 sm:px-4 pb-4  overflow-auto">
            {panicEvents?.results?.data.map((item, index) => {
              return (
                <div key={index}>
                  <div className="sticky top-0 w-full text-GShade2 bg-GShade1 py-1 3xl:py-2 my-1 rounded-md font-semibold px-2 sm:px-8 text-xs sm:text-base">
                    {item.date}
                  </div>
                  <div className=" w-full space-y-2   divide-y divide-slate-600/20 ">
                    {item.notification?.map((subItem, index) => (
                      <div
                        key={index}
                        className={
                          "px-2 py-2 sm:px-8 sm:py-4 h-full flex justify-center  items-center gap-4 group text-GShade4 bg-GShade3 rounded-md "
                        }
                      >
                        <div className="space-y-1 flex-1 basis-1/2">
                          <p className="text-sm 2xl:text-base font-medium text-start">
                            {subItem.content}
                          </p>
                          <div className="flex justify-start items-center gap-0.5 xs:gap-1 sm:gap-2 md:gap-3 3xl:gap-4">
                            <div className="flex justify-center items-center gap-1 w-max px-4 text-[8px] xl:text-xs py-1 rounded-full font-medium bg-GShade4 text-gray-700">
                              <GoAlert className="h-3 w-3 text-slate-500 " />
                              <p>{subItem.notification_type}</p>
                            </div>

                            <div className="flex justify-center items-center gap-1 w-max px-4 text-[8px] xl:text-xs py-1 rounded-full font-medium bg-GShade4 text-gray-700">
                              <GoClock className="h-3 w-3 text-slate-500 " />
                              <p>{subItem.time}</p>
                            </div>

                            <div className="flex justify-center items-center gap-1 w-max px-4 text-[8px] xl:text-xs py-1 rounded-full font-medium bg-GShade4 text-gray-700">
                              <LuMapPin className="h-3 w-3 text-slate-500 " />
                              <a
                                href={`https://maps.google.com/maps?q=${subItem.data.lat},${subItem.data.lng}&hl=es;z=14&amp;output=embed`}
                                className=""
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                See map
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="hidden group-hover:block text-brandColor/60"></div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>

          {/* pagination  */}
          <div className="w-full flex-shrink-0 pb-1 pr-1">
            {panicEvents ? (
              <div className="flex justify-end gap-2 items-center text-white">
                {panicEvents && panicEvents.previous ? (
                  <p
                    onClick={() => setPagination(panicEvents.previous)}
                    className="cursor-pointer text-bold text-[10px] sm:text-sm text-lightPrimary  bg-GShade3  px-6 py-1 rounded-md flex justify-center items-end gap-x-1"
                  >
                    Previous
                  </p>
                ) : (
                  <p className="text-bold text-[10px] sm:text-sm text-lightPrimary  bg-GShade3  opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                    Previous
                  </p>
                )}
                {panicEvents && panicEvents.next ? (
                  <p
                    onClick={() => setPagination(panicEvents.next)}
                    className="cursor-pointer text-bold text-[10px] sm:text-sm text-lightPrimary  bg-GShade3  px-6 py-1 rounded-md flex justify-center items-end gap-x-1"
                  >
                    Next
                  </p>
                ) : (
                  <p className="text-bold text-[10px] sm:text-sm text-lightPrimary  bg-GShade3  opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                    Next
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* loader gif */}
      <div
        className={
          (isLoading ? "block" : "hidden") +
          "  absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-10"
        }
      >
        <img
          className="h-44 w-44 object-contain"
          src="/images/loading.gif"
          alt="loader"
        />
      </div>
      {/* Modals */}
      <>
        {/* add Emergency contact */}
        <Transition appear show={isOpenAddEmergencyContact} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 text-GShade4"
            onClose={() => (setIsOpenAddEmergencyContact(false), reset())}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-GShade1 bg-opacity-60 " />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-GShade2 dark:bg-transparent p-3 sm:p-6 align-middle shadow-xl transition-all">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={
                        "space-y-4 p-4 rounded-lg bg-GShade2 dark:bg-gray-700  h-auto max-w-3xl m-auto text-white"
                      }
                    >
                      <div>
                        <h2 className="capitalize font-bold  text-left text-xl sm:text-2xl lg:text-3xl">
                          Add Emergency Contact
                        </h2>
                        <p className="text-left font-medium text-sm ">
                          Please insert existing Satarko phone Number
                        </p>
                      </div>
                      {/* input fileds */}
                      <div className="space-y-4">
                        {/* name */}
                        <div className="">
                          <input
                            type="text"
                            className="border-b border-slate-200 px-2 py-1 w-full placeholder-slate-200 outline-none  bg-transparent dark:bg-gray-700"
                            placeholder="User Phone Number"
                            {...register("phone", { required: true })}
                          />
                          {/* errors will return when field validation fails  */}
                          {errors.phone && (
                            <span className="text-xs text-red-500">
                              This field is required*
                            </span>
                          )}
                        </div>
                      </div>
                      {/* buttons */}
                      <div className="space-y-3">
                        <input
                          className="w-full bg-GShade4 py-1 text-GShade2 font-bold rounded-full cursor-pointer"
                          value="Done"
                          type="submit"
                        />
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* delete Emergency contact */}
        <Transition appear show={isOpenEmergencyContactDelete} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsOpenEmergencyContactDelete(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-GShade3 bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-red-600  align-middle transition-all">
                    <div className="bg-GShade2 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationCircleIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-red-600"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-GShade4"
                          >
                            Delete Emergency Contact
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-GShade4">
                              Are you sure you want to delete your emergency?
                              All of your data will be permanently removed. This
                              action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-GShade2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteEmergencyContact();
                          setIsLoading(true);
                        }}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        data-autofocus
                        onClick={() => setIsOpenEmergencyContactDelete(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto outline-none"
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    </section>
  );
};

export default EmergencyContact;
