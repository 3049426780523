import { AllRoutes } from "./AllRoutes";
import "./App.css";
import "./firebase";
import { FireNotification } from "./firebase";

function App() {
  return (
    <div className="App">
      <AllRoutes />
      <FireNotification />
    </div>
  );
}

export default App;
