import { createSlice } from "@reduxjs/toolkit";
import { toast, ToastContainer } from "react-toastify";
import { setCookie } from "../../Frontend/Helpers/Session";
import Cookies from "js-cookie";

function updateValue(arr, newValue) {
  // update value
  if (!arr.includes(newValue)) {
    arr.push(newValue);
  }
  return arr;
}

export function reset_alarm_cookie(alarm_obj) {
  var updated_alarm_cookie = JSON.stringify(alarm_obj);

  setCookie("alarm_dict", updated_alarm_cookie);
}
export function reset_alarm_room_cookie(alarm_obj) {
  var updated_alarm_cookie = JSON.stringify(alarm_obj);

  setCookie("alarm_room", updated_alarm_cookie);
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: "",
    errors: [],
    notification_id: "",
    notification_token: "",
  },

  reducers: {
    getnotifications: (state, action) => {
      state.notifications = action.payload;
      // console.log(action.payload.data);
      if (action.payload.data.notification_type !== "WIFI_SEARCH") {
        toast.info(
          <div className="text-left w-full">
            {(action.payload.data.notification_type === "CAPTURE_ON_DEMAND" ||
              action.payload.data.notification_type === "CAPTURE_ON_ALARM") && (
              <p className="text-xs font-medium text-GShade2">
                {action.payload.data.notification_type === "CAPTURE_ON_ALARM"
                  ? "Capture on Alarm"
                  : "Capture on Demand"}
              </p>
            )}

            <p>{action.payload.data.content}</p>
          </div>,
          {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      if (
        action.payload.data.notification_type === "DEVICE_TRIGGER" ||
        (action.payload.data.notification_type === "LID_OPEN" &&
          action.payload.data.is_alarm === true)
      ) {
        // check for trigger
        const alarm_dict_cookie = Cookies.get("alarm_dict")
          ? JSON.parse(Cookies.get("alarm_dict"))
          : {};
        // console.log("cookie", alarm_dict_cookie);

        // console.log(action.payload.data);
        let hub_id = action.payload.data.hub_serial_id;
        let device_id = action.payload.data.device_id;

        if (alarm_dict_cookie) {
          let alarm_dict = alarm_dict_cookie;

          // check if key exists or not if exist update the value of the key with new value & reset the cookie
          if (hub_id in alarm_dict) {
            let device_arr = alarm_dict[hub_id];
            //  console.log('device_arr', device_arr)
            let updated_device_arr = updateValue(device_arr, device_id);
            // console.log("updated device arr", updated_device_arr);
            // reset the key value
            alarm_dict[hub_id] = updated_device_arr;
            reset_alarm_cookie(alarm_dict);
          } else {
            // console.log('key not in alarm dict, so set a new key with new value')
            // generate a new key and also add the values to the key
            alarm_dict[`${hub_id}`] = [device_id];
            reset_alarm_cookie(alarm_dict);
          }
        } else {
          const temp_alarm_dict = {};
          // generate a new key and also add the values to the key
          temp_alarm_dict[`${hub_id}`] = [device_id];
          reset_alarm_cookie(temp_alarm_dict);
        }
      }

      if (
        action.payload.data.notification_type === "HUB_SIREN_END" ||
        action.payload.data.notification_type === "HUB_SIREN_END_BY_REMOTE"
      ) {
        const alarm_dict_cookie = Cookies.get("alarm_dict")
          ? JSON.parse(Cookies.get("alarm_dict"))
          : {};
        // console.log("cookie", alarm_dict_cookie);

        // console.log(action.payload.data);
        let hub_id = action.payload.data.hub_serial_id;
        if (alarm_dict_cookie) {
          let alarm_dict = alarm_dict_cookie;
          // check if key exists or not if exist update the value of the key with new value & reset the cookie
          if (hub_id in alarm_dict) {
            delete alarm_dict[hub_id];
            // console.log(alarm_dict);
            reset_alarm_cookie(alarm_dict);
          }
        }
      }
    },
    getnotificationsID: (state, action) => {
      state.notification_id = action.payload;
    },
    getnotificationsToken: (state, action) => {
      state.notification_token = action.payload;
    },

    getErrors: (state, action) => {
      state.errors = action.payload;
      //   console.log(action.payload, "error from reducer")
      toast.error(`${action.payload.error}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    getAlarmRemove: (state, action) => {
      // console.log(action.payload);
      const alarm_dict_cookie = Cookies.get("alarm_dict")
        ? JSON.parse(Cookies.get("alarm_dict"))
        : {};

      let hub_id = action.payload;

      if (alarm_dict_cookie) {
        let alarm_dict = alarm_dict_cookie;
        // check if key exists or not if exist update the value of the key with new value & reset the cookie
        if (hub_id in alarm_dict) {
          delete alarm_dict[hub_id];
          // console.log(alarm_dict);
          reset_alarm_cookie(alarm_dict);
        }
      }
    },
  },
});
<ToastContainer />;

// Action creators are generated for each case reducer function
export const {
  getnotifications,
  getnotificationsID,
  getnotificationsToken,
  getErrors,
  getAlarmRemove,
} = notificationSlice.actions;

export default notificationSlice.reducer;
