import { createSlice } from "@reduxjs/toolkit";

export const NavigationSlice = createSlice({
  name: "navLocation",
  initialState: {
    navLocations: [],
  },

  reducers: {
    getNavLocation: (state, action) => {
      state.navLocations = action.payload;
      // console.log(action.payload, "reducer");
    },
  },
});

export const { getNavLocation } = NavigationSlice.actions;

export default NavigationSlice.reducer;
